@import '~react-chat-elements/dist/main.css';
@import "~react-datepicker/dist/react-datepicker.min.css";
@import "./custom/Datepicker.scss";
@import "./custom/Calendar.scss";
@import "~react-toastify/dist/ReactToastify.css";

@tailwind components;
@tailwind base;
@tailwind utilities;

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, button, textarea {
  &:focus {
    outline: none;
  }
}

input[type=file]::-webkit-file-upload-button { /* chromes and blink button */
  cursor: pointer;
}

.bg-media {
  background: repeating-conic-gradient(
      rgb(234 234 234) 0%,
      rgb(216 216 216) 25%,
      transparent 0%,
      transparent 50%
    )
    50% center/20px 20px;
}

.product-card--active {
  background-color: #3d7368;

  * {
    color: white;
  }

  .fill-primary {
    fill: white;
  }
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.filter-table {
  .react-select__control {
    min-height: 40px;
    padding: 0;
  }

  .react-select__menu {
    right: 0;
    width: 160px;
    text-align: left;
  }
}

.loader {
  width: 48px;
  height: 48px;
  border: 3px solid #fff;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: 3px solid transparent;
  border-bottom-color: #ff3d00;
}

video-js {
  width: 100% !important;
}

.react-tiny-popover-container {
  z-index: 9999;
}

.react-datepicker-wrapper {
  @apply w-full;
}

.message-item {
  img {
    display: inline-block;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
